<template>
  <section class="w-full p-4" id="filtro-ejecucion">
    <div class="accordion-item">
      <div id="faq-accordion-content-1" class="accordion-header flex justify-between">
        <button
          class="accordion-button text-sm font-medium focus:outline-none text-primary-1 dark:text-white"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#faq-accordion-collapse-1"
          aria-expanded="true"
          aria-controls="faq-accordion-collapse-1"
        >
          Filtros de busqueda → (Año: {{ getterFilters.year ?? 'Todos' }} - Mes Inicial: {{ getterFilters.month_start ?? 'Todos' }} - Mes Final: {{ getterFilters.month_end ?? 'Todos' }} - Cliente: {{ getterFilters.customer ?? 'Todos' }} - Departamento: {{ getterFilters.dptobodega ? getterFilters.dptobodega : getterFilters.dptobodega === '' ? 'Sin asignar' : 'Todos' }} - Bodega: {{ getterFilters.bodega ? getterFilters.bodega : getterFilters.bodega === '' ? 'Sin asignar' : 'Todas' }} )
        </button>
      </div>
      <div id="faq-accordion-collapse-1" class="accordion-collapse collapse show mt-2"
           aria-labelledby="faq-accordion-content-1" data-bs-parent="#faq-accordion-1">
        <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
          <div v-show="loading" style="width:100%; height: 100px" class="rounded-md icono-carga flex flex-col justify-center items-center relative">
            <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" style="width:100%; height: 100px"/>
            <h3 class="text-red-800 font-bold text-sm">Por favor espere... ajustando filtros</h3>
          </div>
          <div v-show="!loading" class="grid grid-cols-7 gap-x-6 gap-y-2">
            <div class="col-span-7 sm:col-span-1">
              <label for="start_year" class="block text-sm font-medium text-gray-700">Año:</label>
              <select class="form-select form-select-sm mt-1" id="start_year" v-model="getterFilters.year">
                <!-- <option :value="null">-- Seleccione una opción --</option> -->
                <option v-for="(row,k) in getterOptionsFilters.Year" :key="k" :value="row">{{ row }}</option>
              </select>
            </div>
            <div class="col-span-7 sm:col-span-1">
              <label for="start_month" class="block text-sm font-medium text-gray-700">Mes Inicial:</label>
              <select class="form-select form-select-sm mt-1" id="start_month" v-model="getterFilters.month_start">
                <!-- <option :value="null">-- Seleccione una opción --</option> -->
                <option v-for="(row,k) in completeMonths" :key="k" :value="row.id">{{ row.name }}</option>
              </select>
            </div>
            <div class="col-span-7 sm:col-span-1">
              <label for="end_month" class="block text-sm font-medium text-gray-700">Mes Final:</label>
              <select class="form-select form-select-sm mt-1" id="end_month" v-model="getterFilters.month_end">
                <!-- <option :value="null">-- Seleccione una opción --</option> -->
                <option v-for="(row,k) in filterMonths" :key="k" :value="row.id">{{ row.name }}</option>
              </select>
            </div>
            <div class="col-span-7 sm:col-span-1">
              <label for="customers" class="block text-sm font-medium text-gray-700">Cliente:</label>
              <select class="form-select form-select-sm mt-1" id="customers" @change="onChangeCliente" v-model="getterFilters.customer">
                <option :value="null">-- Seleccione una opción --</option>
                <option v-for="item in getterOptionsFilters.Cliente" :key="item"> {{ item }}</option>
              </select>
            </div>
            <div class="col-span-7 sm:col-span-1">
              <label for="dptobodegas" class="block text-sm font-medium text-gray-700">Departamento:</label>
              <select class="form-select form-select-sm mt-1" id="dptobodegas" @change="onChangeCliente" v-model="getterFilters.dptobodegas">
                <option :value="null">-- Seleccione una opción --</option>
                <option v-for="item in getterOptionsFilters.dptobodega" :key="item"> {{ item }}</option>
              </select>
            </div>
            <div class="col-span-7 sm:col-span-1">
              <label for="bodega" class="block text-sm font-medium text-gray-700">Bodega:</label>
              <select class="form-select form-select-sm mt-1" id="bodega" v-model="getterFilters.bodega">
                <option :value="null">-- Seleccione una opción --</option>
                <option v-for="item in getterOptionsFilters.Bodega" :key="item"> {{ item }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="flex flex-col sm:flex-row justify-between items-center border-t border-gray-300 dark:border-dark-5" ></div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'FiltersCostos',
  components: {
  },
  setup (props, context) {
    const store = useStore()
    const completeMonths = ref([])

    const loading = computed(() => {
      return store.getters['filtersCardCostosClientes/getLoadingFilters']
    })

    const getterFilters = computed(() => {
      return store.getters['filtersCardCostosClientes/getFilters']
    })

    const getterOptionsFilters = computed(() => {
      return store.getters['filtersCardCostosClientes/getOptionsFilters']
    })

    /*  */
    const fetchBySelect = () => {
      store.dispatch('filtersCardCostosClientes/getFiltersAction', true).then((e) => {
        // console.log('Get: ', e)
      }).catch(err => {
        console.error(err.message)
      })
    }

    const completeMonthsFunc = async (months) => {
      // console.log(months)
      const nameMonths = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
      ]
      return months.map((item, index) => {
        return {
          id: item,
          name: nameMonths[index]
        }
      })
    }

    /**/
    const filterMonths = computed(() => {
      return completeMonths.value.filter((itemMonth) => {
        return parseInt(getterFilters.value.month_start) <= parseInt(itemMonth.id)
      })
    })

    const onChangeCliente = () => {
      // console.log('Entre aqui cliente')
      fetchBySelect()
    }

    /* watch(props.modelValue, (filter) => {
      console.log('Entre aqui')
      fetchByClient()
    }) */

    onMounted(() => {
      store.dispatch('filtersCardCostosClientes/getFiltersAction').then(async () => {
        completeMonths.value = await completeMonthsFunc(getterOptionsFilters.value.Mes)
      })
    })

    return {
      getterFilters,
      filterMonths,
      getterOptionsFilters,
      completeMonthsFunc,
      completeMonths,
      onChangeCliente,
      loading
    }
  }
}
</script>

<style scoped>

</style>
